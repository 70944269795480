import React from "react";
import "./StoryPart.css";
import storyImage from "../images/background2.png";

function StoryPart() {
  return (
    <div className="story-part">
      <div className="story-text">
        <h3>OUR STORY</h3>
        <div className="story-title">
          <h2>We’re Changing the</h2>
          <h2>Way the Businesses</h2>
          <h2>Engage Audiences</h2>
        </div>
        <p>
          At Bugu Solutions, we are pioneering the future of AI with our
          exceptional AIGC consulting services. Using advanced AI-generated
          content technology, we help businesses and individuals tackle
          challenges, enhance efficiency, and reduce costs, making them more
          competitive. Whether you're a company needing efficient content
          creation or an individual seeking personalized solutions, our precise,
          customized consulting services drive greater success in your
          endeavors.
        </p>
      </div>
      <div className="story-image">
        <img src={storyImage} alt="Story" />
      </div>
    </div>
  );
}
export default StoryPart;
