import React from "react";
import "./TechnologyPart.css";
import background5 from "../images/background5.png";

function TechnologiesPart() {
  return (
    <div
      className="technologies-page"
      style={{ backgroundImage: `url(${background5})` }}
    >
      <div className="technologies-text">
        <div className="technologies-description">
          <h3>OUR TECHNOLOGIES</h3>
          <h2>A Commitment to</h2>
          <h2>Excellence and</h2>
          <h2>Innovation</h2>
          <p>
            By harnessing the power of large language models (LLMs) and
            Retrieval-Augmented Generation, producing highly relevant and
            contextually accurate text.
          </p>
          <p>
            By integrating cutting-edge techniques like stable diffusion for
            image generation, creating visually stunning and realistic images.
          </p>
          <p>
            By combining the capabilities of LLMs and advanced image generation,
            AI generative content provides businesses with sophisticated tools
            to enhance their digital presence, streamline content creation, and
            engage audiences more effectively.
          </p>
        </div>
        <button className="read-more-btn">Read More →</button>
      </div>
    </div>
  );
}

export default TechnologiesPart;
