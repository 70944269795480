import React from "react";
import "./ServicePart2.css";
import serviceImage from "../images/background4.png";

function ServicePart2() {
  return (
    <div className="service-page">
      <div className="service-content">
      <div className="service-image">
          <img src={serviceImage} alt="Service" />
        </div>
        <div className="service-description">
          <h2>E-COMMERCE</h2>
          <h2>SERVICE</h2>
          <p>
            We provide state-of-the-art image and video generation for
            eCommerce, helping businesses create compelling visual content that
            drives customer engagement and boosts sales.
          </p>
         <button className="read-more">Read More &rarr;</button>
        </div>
        
      </div>
    </div>
  );
}

export default ServicePart2;
