import React from "react";
import "./ContactPart.css";

function ContactPart() {
  //send email function
    const openGmail = () => {
    const recipient = "example@example.com";
    const subject = "Your Subject Here";
    const body = "Your email body text here";

    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="contact-part">
      <h2 className="contact-title">Connect With Us</h2>
      <div className="contact-content">
        <div className="contact-info">
          <h3 className="contact-heading">BUGU SOLUTIONS</h3>
          <p>Tel: 1-226-698-8198</p>
          <p onClick={openGmail}>Email: wei@bugusolutions.com</p>
        </div>
        <div className="contact-message">
          <p>We are here to help you drive change.</p>
          <p>Together we will shape the future.</p>
        </div>
      </div>
    </div>
  );
}

export default ContactPart;
