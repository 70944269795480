import React from 'react';
import './ServicePart.css';
import serviceImage from '../images/background3.png';

function ServicePart() {
  return (
    <div className="service-page">
      <div className="service-header">
        <h3>OUR SERVICES</h3>
        <h2>Exceptional Offerings for Business and Individuals</h2>
        <h2>Business and Individuals</h2>
      </div>
      <div className="service-content">
        <div className="service-description">
          <h2>Intelligent</h2>
          <h2>Document</h2>
          <h2>Processing</h2>
          <p>
            Our artificial intelligence service specializes in retrieval-augmented generation, excelling in semantic search, summarization, and key information retrieval. This enhances the accuracy and relevance of the results.
          </p>
          <button className="read-more">Read More →</button>
        </div>
        <div className="service-image">
          <img src={serviceImage} alt="Service" />
        </div>
      </div>
    </div>
  );
}

export default ServicePart;
