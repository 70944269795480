import React from "react";
import "./IntroductionPart.css";

function IntroductionPart() {
  return (
    <div className="main-page">
      <div className="IntroductionPart-content">
        <h1>THE FUTURE OF ARTIFICIAL </h1>
        <h1>INTELLIGENCE IS HERE</h1>
        <p>Explore the Best AI Generative Content with Bugu Solutions</p>
      </div>
    </div>
  );
}
export default IntroductionPart;
