import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

function Navbar() {
  //nav bar shows when scroll up, disappears when scroll down
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScroolY, setLastScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScroolY) {
        //when scroll down, the navbar disappears
        setShowNavbar(false);
      } else {
        //when scroll down, the navbar shows up
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroolY]);

  const navigate = useNavigate();

  return (
    <>
      <nav className={`navbar ${showNavbar ? "visible" : "hidden"}`}>
        <div className="navbar-container">
          <div className="navbar-columns">
            <div className="navbar-logo-container">
              <div className="navbar-logo" onClick={() => navigate("/")}>
                BUGU SOLUTIONS
              </div>
            </div>
            <div className="navbar-items-container">
              <div className="nav-item" onClick={() => navigate("/home")}>
                Home
              </div>

              <div className="nav-item" onClick={() => navigate("/services")}>
                Services
              </div>
              <div className="nav-item" onClick={() => navigate("/technology")}>
                Technology
              </div>
              <div className="nav-item" onClick={() => navigate("/contact")}>
                Contact
              </div>
            </div>

            {/* button for mobile version */}
            <button className="menu-button" onClick={toggleMenu}>
              ☰
            </button>
          </div>
          {menuOpen && (
            <div className="dropdown-menu">
              <div
                className="dropdown-item"
                onClick={() => {
                  navigate("/home");
                  toggleMenu();
                }}
              >
                Home
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  navigate("/services");
                  toggleMenu();
                }}
              >
                Services
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  navigate("/technology");
                  toggleMenu();
                }}
              >
                Technology
              </div>
              <div
                className="dropdown-item"
                onClick={() => {
                  navigate("/contact");
                  toggleMenu();
                }}
              >
                Contact
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
