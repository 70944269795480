// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #fff; 
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e0e0e0; 
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1); 
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; 
  }
  
  .footer-content p {
    margin: 0;
    font-size: 1rem;
    color: #333; 
  }
  
  .social-icons a {
    color: #333;
    margin: 0 10px;
    font-size: 1.5rem;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #000; 
  }
  

  @media (max-width: 768px) {
    
    .footer-content {
      flex-direction: column;
      gap: 15px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,0CAA0C;EAC5C;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,SAAS;IACT,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;;EAGA;;IAEE;MACE,sBAAsB;MACtB,SAAS;IACX;EACF","sourcesContent":[".footer {\n    background-color: #fff; \n    padding: 20px 0;\n    text-align: center;\n    border-top: 1px solid #e0e0e0; \n    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1); \n  }\n  \n  .footer-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 20px; \n  }\n  \n  .footer-content p {\n    margin: 0;\n    font-size: 1rem;\n    color: #333; \n  }\n  \n  .social-icons a {\n    color: #333;\n    margin: 0 10px;\n    font-size: 1.5rem;\n    transition: color 0.3s;\n  }\n  \n  .social-icons a:hover {\n    color: #000; \n  }\n  \n\n  @media (max-width: 768px) {\n    \n    .footer-content {\n      flex-direction: column;\n      gap: 15px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
