import React from "react";
import Navbar from "./Navbar";
import IntroductionPart from "./IntroductionPart";
import StoryPart from "./StoryPart";
import ServicePart from "./ServicePart";
import ServicePart2 from "./ServicePart2";
import TechnologiesPart from "./TechnologyPart";
import ContactPart from "./ContactPart";
import Footer from "./Footer";

function MainPage() {
  return (
    <div className="main-page">
      <Navbar />
      <IntroductionPart />
      <StoryPart />
      <ServicePart />
      <ServicePart2 />
      <TechnologiesPart />
      <ContactPart />
      <Footer />
    </div>
  );
}
export default MainPage;
